.container{
  padding-bottom: 50px;
  background: rgb(99,181,255);
  background: -moz-linear-gradient(90deg, rgba(99,181,255,1) 32%, rgba(85,68,255,1) 50%, rgba(103,71,255,1) 58%);
  background: -webkit-linear-gradient(90deg, rgba(99,181,255,1) 32%, rgba(85,68,255,1) 50%, rgba(103,71,255,1) 58%);
  background: linear-gradient(90deg, rgba(99,181,255,1) 32%, rgba(85,68,255,1) 50%, rgba(103,71,255,1) 58%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#63b5ff",endColorstr="#6747ff",GradientType=1);
}

.rwd-table {
    margin: auto;
    min-width: 300px;
    max-width: 100%;
    border-collapse: collapse;
   
  }

  .rwd-table tr:first-child {
    border-top: none;
    background: #AB0CFF;
    color: #fff;
  }
  
  .rwd-table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f9fc;
  }
  
  .rwd-table tr:nth-child(odd):not(:first-child) {
    background-color: #ebf3f9;
  }
  
  .rwd-table th {
    display: none;
  }
  
  .rwd-table td {
    display: block;
  }
  
  .rwd-table td:first-child {
    margin-top: .5em;
  }
  
  .rwd-table td:last-child {
    margin-bottom: .5em;
  }
  
  .rwd-table td:before {
    content: attr(data-th) ":";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: #000;
  }
  
  .rwd-table th,
  .rwd-table td {
    text-align: center;
  }
  
  .rwd-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
  }
  
  .rwd-table tr {
    border-color: #bfbfbf;
  }
  
  .rwd-table th,
  .rwd-table td {
    padding: .5em 1em;
  }
  @media screen and (max-width: 601px) {
    .rwd-table td {
      text-align: left;
    }
    .rwd-table tr:nth-child(2) {
      border-top: none;
    }
  }
  @media screen and (min-width: 600px) {
    .rwd-table tr:hover:not(:first-child) {
      background-color: #d8e7f3;
    }
    .rwd-table td:before {
      display: none;
    }
    .rwd-table th,
    .rwd-table td {
      display: table-cell;
      padding: .25em .5em;
    }
    .rwd-table th:first-child,
    .rwd-table td:first-child {
      padding-left: 0;
    }
    .rwd-table th:last-child,
    .rwd-table td:last-child {
      padding-right: 0;
    }
    .rwd-table th,
    .rwd-table td {
      padding: 1em !important;
    }
  }
  
