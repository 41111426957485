.App {
  background-color: #553eff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/public/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
 
}

.container-qr {
  width: 100%;
  max-width: 1536px;
  padding: 0 15px 0 15px;
}

.logo_qr {
  opacity: 1;
  text-align: center;
  font-size: xx-large;
  margin-bottom: 15px;
  line-height: 35px;
  color: white;
  font-weight: 900;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.logo_qr > h1 {
  margin-bottom: 5px;
}

.scan-logo {
  background-image: url("/public/scan-logo.webp");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.QR_div {
  position: relative;
}

.svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.loaderQR {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 30;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
}


/*Tablet*/
@media screen and (min-width: 640px) {
  .QR_div {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

/*Laptop*/
@media screen and (min-width: 1024px) {
  .container-qr {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0px 120px 0px 0px;
  }
  .logo_qr {
    opacity: 0;
  }
  .QR_div {
    width: 300px;
  }
}

/*Desktop*/
@media screen and (min-width: 1280px) {
  .container-qr {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0px 100px 0px 0px;
  }
  .QR_div {
    width: 550px;
  }
}
